#header {
  height: 8vh;
  background-color: grey;
  color: white;
  text-align: center !important;
  font-size: 30px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border-radius: 15px;
  line-height: 70px;
  width: 100%;
}

.wrapper {
    display: grid;
    margin:5px;
    padding:5px;
    height: 100%;
    overflow: auto;
    text-align:justify;
  }

  .videoFrames {
    width:200px;
	  height:200px;
	  margin:10px;
	  padding:10px;
    white-space:pre;
	  border-radius: 25px;
    border: gray solid 5px;
    display: flex;
    justify-content: center;
    background-image: url('./../../public/remoteUser.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    mix-blend-mode: multiply;
  }

  img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .localVideoImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

 .remoteVideo {
    float: left;
    width: 15%;
    height: 850px;
    overflow-y: scroll;
    margin: 5px;
 }

 .localVideo {
    width: 55%;
    float: left;
    height: 640px;
    padding:10px;
    margin: 10px;
    border: gray solid 5px;
    border-radius: 25px;
    display: flex;
    background-image: url('./../../public/defaultpic.jpeg');
    background-repeat: no-repeat;
    background-position: center;
 }

 .localDefaultVideo{
  height: 500px;
	margin:10px;
 }


 /* chat */
 .chatWindow{
  margin: 9px; 
  float: right;
  width: 25%;
  height: 599px;
  border-radius: 25px;
  border: gray solid 5px;
  overflow-y: scroll;
 }

 .chatHeader {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border: 2px solid grey;
  background-color: lightblue;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  float:right;
  width: 20%;
  padding-left: 4%;
 }

 .chattitle {
    text-align: center;
    color:black;
    font-family:'Times New Roman', Times, serif;
    float: right;
    width: 20%;
    margin-right: 65px;
    border: gray solid 2px;
    border-radius: 5px;
    margin-bottom: 0px;
 }

 .container {
    border: 2px solid #dedede;
    background-color: lightblue;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    float:left;
    width: 80%;
    padding-left: 4%;
    display: flex;
  }
  
  .darker {
    border-color: #ccc;
    background-color: #ACE1AF;
    width: 80%;
    padding-left: 4%;
    float: right;
  }
  
  .container::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .container img {
    float: left;
    max-width: 60px;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .container img.right {
    float: right;
    margin-left: 20px;
    margin-right:0;
  }

  .sender{
    float: left;
    margin-top: 1px;
    font-style: italic;
    font-weight:300;
  }
  
  .message {
    padding: 5px;
    border-radius: 15px;
    text-align:justify;
    font-family:Georgia, 'Times New Roman', Times, serif;
  }

  .timeStamp {
    color:grey ;
    margin-top: 40px;
    margin-left: 32px;
  }

  .darkerTimeStamp {
    color:grey ;
    margin-top: 40px;
    margin-left: 270px;
  }
  
  .time-left {
    float: right;
    color: #999;
    margin-left: 120px;
  }

  .footer {
    position: fixed;
    /* left: 0; */
    bottom: 0;
    /* width: 100%; */
    color: white;
    text-align: center;
    width: 82%;
    left: 16%;
    padding: 5px;
    margin: 15px;
    height:fit-content;
 }

 /* bottom bar */

  .button{
    background-color:deepskyblue; 
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 2px 12px;
    cursor: pointer;
    border-radius: 50%;
  }

  button:hover {
    background-color: aquamarine;
  }

  .first{
    width: 67%;
    height: 80px;
    text-align: center;
    border-radius: 25px;
    float: left;
  }

  .txtBoxDiv{
    float: left;
    width: 22%;
    border: gray solid 3px;
    text-align: center;
    height: 80px;
    border-radius: 25px;
  }

.disconnect{
  background-color: red;
 }

.flexContainer {
    display: flex;
    width: 31%;
    padding-left: 28px;
    padding-top: 5px;
    height: 30%;
}

.submit{
  width: 25%;
  height: 56px;
  border: gray solid 3px;
  border-radius: 8px;
  background-color:beige;
}
.submit:hover {
background-color: lightblue;
}

.inputField {
  width: 300% ;
  float: left;
  height: 50px;
  border: gray solid 3px;
  border-radius: 8px;
  font-size: larger;
  font-family:'Times New Roman', Times, serif;
}

.nocameraimg {
  background-color: transparent;
  width: 25px;
  height:25px;
  mix-blend-mode: multiply;
}

.muteimg{
  width: 25px;
  height:25px;
  mix-blend-mode: multiply;
}

.localUserLabel{
  float: right;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: larger;
}

.fromImg {
  margin-left: 10px;
}

.message{
  margin-left: 40px;
}